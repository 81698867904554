
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  getInvoiceData,
  initCouponData,
  CouponData,
  addCouponPackage,
  getCouponGiftPackDetail,
  editCouponGiftPack,
  getIncludeData,
  getGiftPackCodeList,
  editCouponPackage,
} from '@/api/marketing/coupon';
import { PaginationData } from '@/api/operation/customer';
const _ = {
  findIndex: require('lodash/findIndex'),
};
const STATUS = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '上架中',
  },
  {
    value: 2,
    label: '已下架',
  },
];
const COUPON_TYPE = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '满减券',
    children: [
      {
        value: 1,
        label: '通用券',
      },
      {
        value: 2,
        label: '商品券',
      },
      {
        value: 3,
        label: '品类券',
      },
    ],
  },
  {
    value: 2,
    label: '折扣券',
    children: [
      {
        value: 1,
        label: '通用券',
      },
      {
        value: 2,
        label: '商品券',
      },
      {
        value: 3,
        label: '品类券',
      },
    ],
  },
];
@Component
export default class AddGiftPack extends Vue {
  is_loading: boolean = false;
  is_show_coupon_modal: boolean = false;
  code_number: boolean = false;
  form_item_layout: any = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };
  coupon_data: PaginationData<CouponData> = initCouponData();
  form_value: any = {
    name: '',
    type: [0],
    status: 0,
  };
  selectedRowKeys: any = [];
  selectedRows: any = [];
  select_coupon_list: any = [];
  gift_pack_name: any = '';
  page: number = 1;
  get couponType() {
    return COUPON_TYPE;
  }
  get couponStatus() {
    return STATUS;
  }

  paginationOption(data?: any) {
    return {
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      total: data.total || 0,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }

  // 关闭模态床
  @changeLoading(['is_loading'])
  async closeModal() {
    this.is_show_coupon_modal = false;
    this.form_value = {
      name: '',
      type: [0],
      status: 0,
    };
    this.selectedRowKeys = [];
    await this.getCouponList({
      limit: 5,
      current: 1,
      grant_type: 2,
    });
  }

  onSelectChange(selectedRowKeys: any, selectedRows: any) {
    this.selectedRowKeys = [];
    this.selectedRowKeys = selectedRowKeys;
    selectedRows.forEach((item: any) => {
      if (!this.selectedRows.map((item: any) => item.id).includes(item.id)) {
        this.selectedRows.push(item);
      }
    });

    // this.selectedRows = Array.from(new Set(this.selectedRows.push(...selectedRows)));
    // console.log(this.selectedRows);

    // const selected_id_arr = this.selectedRowKeys.map((item: any) => item.id);
    // this.coupon_data.detail.forEach((item: any) => {
    //   if (selectedRowKeys.indexOf(item.id) !== -1 && selected_id_arr.indexOf(item.id) === -1) {
    //     this.selectedRowKeys.push(item);
    //   }
    // });
  }

  // 确认添加优惠券
  @changeLoading(['is_loading'])
  async confirmAdd() {
    const list = this.select_coupon_list.map((item: any) => item.id);
    this.selectedRows.forEach((item: any) => {
      if (this.selectedRowKeys.includes(item.id) && !list.includes(item.id)) {
        this.select_coupon_list.push(item);
      }
    });

    await this.closeModal();
  }

  // 切换模态窗分页
  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    await this.getCouponList({
      limit: 5,
      current: data.current,
      name: this.form_value.name,
      status: this.form_value.status,
      type: this.form_value.type[0],
      grant_type: 2,
    });
  }
  // 删除优惠券
  deleteCoupon(item: any) {
    console.log(item);
    this.$confirm({
      title: '确认删除？',
      onOk: () => {
        this.select_coupon_list.splice(
          _.findIndex(this.select_coupon_list, (coupon_item: any) => coupon_item.id === item.id),
          1
        );
      },
    });
  }

  // 新增优惠券礼包
  @changeLoading(['is_loading'])
  async addCouponPackage() {
    if (!this.gift_pack_name) {
      this.$message.error('请输入礼包名称');
      return;
    }
    if (!this.select_coupon_list.length) {
      this.$message.error('请输入包含优惠券');
      return;
    }
    if (this.$route.query.id) {
      const res = await editCouponPackage(+this.$route.query.id, {
        name: this.gift_pack_name,
        coupon_id_str: this.select_coupon_list.map((item: any) => item.id).join(','),
      });
      if (res.status !== 200) {
        this.$message.error((res as any).message);
        return;
      }
      this.$message.success('编辑成功');
    } else {
      const res = await addCouponPackage({
        name: this.gift_pack_name,
        coupon_id_str: this.select_coupon_list.map((item: any) => item.id).join(','),
      });
      if (res.status !== 200) {
        this.$message.error((res as any).message);
        return;
      }
      this.$message.success('新增成功');
    }

    this.$router.go(-1);
  }

  handleChange2(data: any) {
    this.page = data.current;
  }

  // 获取优惠券详情
  @changeLoading(['is_loading'])
  async getCouponGiftPackDetail() {
    const res = await getCouponGiftPackDetail(+this.$route.query.id);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.gift_pack_name = res.data.name;
    this.code_number = res.data.code_number;
    const res2 = await getIncludeData(res.data.coupon_id_str);
    if (res2.status !== 200) {
      this.$message.error((res2 as any).message);
      return;
    }
    this.select_coupon_list = res2.data.detail;
  }

  paginationOption2(data: any) {
    return {
      showTotal: () => `共${data.length}条，每页5条`,
      pageSize: 5,
      total: data.length,
      current: this.page,
    };
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async clickSeachBtn() {
    await this.getCouponList({
      limit: 5,
      current: 1,
      name: this.form_value.name,
      status: this.form_value.status,
      type: this.form_value.type.length > 1 ? this.form_value.type[0] : '',
      range: this.form_value.type.length > 1 ? this.form_value.type[1] : '',
      grant_type: 2,
    });
  }

  // 获取优惠券列表
  @changeLoading(['is_loading'])
  async getCouponList(query?: any) {
    const res = await getInvoiceData(query);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.coupon_data = initCouponData(res.data);
  }

  @changeLoading(['is_loading'])
  async created() {
    await this.getCouponList({
      limit: 5,
      current: 1,
      grant_type: 2,
    });
    if (this.$route.query.id) {
      await this.getCouponGiftPackDetail();
    }
    console.log(this.$route);
  }
}
